<template>
    <div class="fullwidth-intro">
        <div class="modal" :class="{dark: theme === 'dark'}" >
            <img alt="Vue logo" src="../assets/img/subliminal-channel-logo-animated.gif">
        </div>
    </div>
</template>

<script>
export default {
    props: ['text', 'theme']
}
</script>


<style >
.modal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.modal.dark{
    color: white;
    background: black;
}

</style>