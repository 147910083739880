<template>
  <div class="HomeScreen">
    <LoadingScreen :isLoading="isLoading" :data-muted="isMuted" :data-play="isPlaying" @click="toggleModal" />

  <div v-if="!isLoading" class="video-wrapper">
    <video ref="video" src="../assets/media/SUBLIMINAL-CHANNEL-DEMO.mp4" @canplay="updatePaused" @playing="updatePaused" @pause="updatePaused" playsinline loop autoplay></video><br>
   <div class="controls">
    <button v-show="paused" @click="play">Play</button>
    <button v-show="playing" @click="pause">Pause</button>
    <button v-if="!buttonState" @click="toggleMute">Sound Off</button>
    <button v-if="buttonState" @click="toggleMute">Sound On</button>
   </div>
  </div>
  </div>
</template>



<script>
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
  name: "HomeScreen",
  components: {
    LoadingScreen
  },
  data() {
    return { 
      isLoading: true,
      showModal: true,
      isMuted: true,
      buttonState: false,
      paused: null
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
      this.isLoading = false
    },
    toggleMute () {
      const vid = this.$refs.video
      vid.muted = !vid.muted
      this.buttonState = !this.buttonState
    },
    updatePaused(event) {
      this.$refs.video = event.target;
      this.paused = event.target.paused;
    },
    play() {
      this.$refs.video.play();
    },
    pause() {
      this.$refs.video.pause();
    }
  },
  computed: {
    playing() { return !this.paused; }
  }
};
</script>

<style scoped>

  .video-wrapper {
    width:100%;
    height:100vh;
    overflow: hidden;
  }

  .video-wrapper video {
    object-fit: cover;
    width:100%;
    height:100%;
  }

   .controls {
    position: absolute;
    mix-blend-mode: difference;
    bottom: 20px;
    width: 100%;
  }

  .video-wrapper button, .controls button {
    font-family: inherit;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 15px 4px 15px;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
    z-index: 1;
    background: none;
  }

  .video-wrapper button:hover, .controls button:hover {
    color: slategray;
    border: 1px solid slategray;
    cursor: pointer;
  }


</style>

