<template>

 <div class="download">
       
   <div class="dl-card">
      <!--a href="https://subliminal-channel.org/SUBLIMINAL-CHANNEL.zip" target="_blank"-->
          <video ref="video" src="../assets/media/subliminal-channel-download-icon.mp4" playsinline loop autoplay></video>
    
      <br>
      <p>We are currently off-air. <br>see you in winter 2021.</p>
      <!--p>Compatible with Chrome browser.<br>
      MAC/WIN/LINUX – 3.1MB</p-->
      <!--a class="download-button" href="https://subliminal-channel.org/SUBLIMINAL-CHANNEL.zip" target="_blank">
      DOWNLOAD EXTENSION
      </a-->

      <!--a class="download-button" href="https://www.youtube.com/watch?v=R0Gaq9rR8A0" target="_blank">
      Watch Last Session
      </a-->
    </div>
    
 </div>

</template>



<style scoped>

.download {
  margin-top: 30vh;
}

.download a {
  text-transform: uppercase;
  display: inline-block;
}

.download p {
  text-transform: uppercase;
  line-height: 1;
}

.download a:hover{
  cursor: pointer;
  mix-blend-mode: difference;
  background-color: white;
  display: inline-flex;
}

.download-button {
    color: slategray;
    text-decoration: none;
    border: 2px solid;
    padding: 14px 25px 7px 25px;
    border-radius: 30px;
}

a.download-button:hover {
  background-color: transparent;
  color: white;
}

video {
  width: 100%;
  max-width: 200px;
}

</style>