<template>
  <div class="about">
 <video ref="video" src="../assets/media/subliminal-channel-icon-animated.mp4" playsinline loop autoplay></video>
 
    <div class="description">
      <p>Subliminal Channel is a geography-based video platform 
        hosting and broadcasting live music sessions on Streetview. 
        We occupy digital spaces and exploit them for virtual raves. 
        Our browser extension provides you access to locations on Google Maps. 
        All events are held in compliance with all hygienic standards and on distance. 
        Find the spots, follow the volume and dive in.<div class="quadrat"></div>
    </p>
    </div>
  </div>
</template>

<style>
h1, p {
  color: white;
}
.about {
  margin-top: 30vh;
}

.about video{
  width: 200px;
  padding-bottom: 5vh;
}

.description {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.3;
    text-transform: uppercase;
}

.description p {
    padding-left: 20px;
    padding-right: 20px;
}

.quadrat {
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 12px;
  transform: translateY(-0.015rem) translateX(5px);
  -webkit-animation: flash 1s infinite;  /* Safari 4+ */
  -moz-animation: flash 1s infinite;  /* Fx 5+ */
  -o-animation: flash 1s infinite;  /* Opera 12+ */
  animation: flash 1s infinite;  /* IE 10+, Fx 29+ */
}

@-webkit-keyframes flash {
  0%   {background-color:white;}
  50% {background-color:slategray;}
  100%   {background-color:white;}



}

</style>