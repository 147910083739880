<template>

  <div id="nav">
    <!--router-link to="/">Download</router-link> | -->
    <span class="logo"> <router-link to="/">Sublimminal Channel</router-link></span>
    
    <!--span><a href="https://subliminal-channel.org/SUBLIMINAL-CHANNEL.zip" target="_blank">Download</a></span-->
    <span><router-link to="/download">Download</router-link></span>
    <span><router-link to="/about">About</router-link></span>
  
  </div>

  <router-view/>
    
</template>




<style>
@font-face {
  font-family: "Basis";
  src: url("./assets/fonts/basis.woff2") format("woff2"),
  url("./assets/fonts/basis.woff") format("woff");
}

body {
  background: black;
  margin: 0;
}

#app {
  font-family: "Basis", 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  text-align: right;
  position: fixed;
  top: 20px;
  z-index: 1;
  width: 100vw;
  mix-blend-mode: difference;
}

#nav span {
  padding-right: 10px;
  padding-left: 1px;
}

#nav a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 10px 4px 10px;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 12.5px;
  font-size: 14px;
}
#nav a.router-link {
  color: white;
}

#nav a:hover {
  color: slategray;
  border: 1px solid slategray;
}

#nav a.router-link-exact-active:hover {
  color: slategray;
  border: 1px solid slategray;
}

#nav span.logo {
  float: left;
  padding-left: 10px;
  text-transform: uppercase;
  font-size: 14px;
  color: white;
  letter-spacing: 0.025em;
}

#nav span.logo a {
  color: white;
  border: none;
}

#nav span.logo a:hover {
  color: slategray;
  border: none;
}



</style>
