<template>
  <div class="msg-beat">
    <div class="infobox">
      <span>LIVE FROM THE PARKING DECK</span> <br>
      <span>Beat – Serum-Kollektiv</span> <br>
      <a href="https://serum-kollektiv.de/">> INFOS</a>
    </div>
    <hr>
    <div class="spatial-image-box">
      <img src="../../assets/img/spatial-preview-web.jpg">
    </div>
    <div class="spatial-box">
      <span>JOIN THE HIDEOUT </span><br>
      <a href="https://spatial.chat/s/serumhideout" onclick="window.open(this.href,'targetWindow','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes, top=100, left=600,width=1024,height=682'); return false;">spatial.chat</a> 

    
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import Modal from '@/components/Modal.vue'


export default {
  name: 'msg-beat',
  components: {
    Modal
  },
  data() {
    return {
        text: 'the nice one',
        img: '../assets/img/subliminal-channel-logo-animated.gif',
        showModal: true
      }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    }
  }
}
</script>

<style scoped>

.msg-beat {
  padding: 20px;
  position: fixed;
  background-color: black;
  z-index: 1;
}

.quadrat {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  transform: translateY(-0.015rem) translateX(1px);
  -webkit-animation: flash 1s infinite;  /* Safari 4+ */
  -moz-animation: flash 1s infinite;  /* Fx 5+ */
  -o-animation: flash 1s infinite;  /* Opera 12+ */
  animation: flash 1s infinite;  /* IE 10+, Fx 29+ */
}


@-webkit-keyframes flash {
  0%   {background-color:#00FF00;}
  100% {background-color:rgb(112, 252, 140);}

}

span {
  font-size: 16px;
  letter-spacing: -.015rem;
  text-transform: uppercase;
  text-align: left;
  color: white;
  margin: 0;
}

a {
  color: slategrey;
  text-decoration: none;
}

hr {
  display: block;
  margin: 0;
  min-width: 100%;
}

.infobox {
  margin-bottom: 20px;

}

.infobox span {
  display: block;

}

.infobox a {
  float: left;
}

.infobox a:hover {
  color: white
}

.spatial-preview {

}
.spatial-image-box {
  float: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

.spatial-image-box img {
 display: block;
  max-width: 100%;
}

.spatial-box {
}

.spatial-box span {
  display: inline-block;
  margin-bottom: 18px;
}

.spatial-box a {
  border: 1px solid;
  padding: 10px 15px 3px 15px;
  border-radius: 150px;
  text-transform: uppercase;
}

.spatial-box a:hover {
  border: 1px solid white;
  color: white;
}

</style>


