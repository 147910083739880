import { createRouter, createWebHistory } from 'vue-router'
import HomeScreen from '../views/HomeScreen.vue'
import Download from '../views/Download.vue'
import About from '../views/About.vue'
import Hanlolo from '../views/sets/msg-hanlolo.vue'
import Marcik from '../views/sets/msg-marcik.vue'
import Beat from '../views/sets/msg-beat.vue'
import KafkaX from '../views/sets/msg-kafkax.vue'

const routes = [
  {
    path: '/',
    name: 'HomeScreen',
    component: HomeScreen
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: About
  },
  {
  path: '/sets/msg-hanlolo',
  name: 'msg-hanlolo',
  component: Hanlolo
  },
  {
    path: '/sets/msg-beat',
    name: 'msg-beat',
    component: Beat
  },
  {
    path: '/sets/msg-marcik',
    name: 'msg-marcik',
    component: Marcik
  },
  {
    path: '/sets/msg-kafkax',
    name: 'msg-kafkax',
    component: KafkaX
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
