<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
      <img alt="Vue logo" src="../assets/img/subliminal-channel-logo-animated.gif">
  </div>
</template>


<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"]
};
</script>



<style>

.loader {
  background-color: black;
  bottom: 0;
  z-index: 2;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
    transition: -webkit-clip-path 1500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: clip-path 1500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: clip-path 1500ms cubic-bezier(0.77, 0, 0.175, 1), -webkit-clip-path 1500ms cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-clip-path: circle(var(--clip-radius, 100%) at 50%);
    clip-path: circle(var(--clip-radius, 100%) at 50%);
    will-change: clip-path;
}

.loader img {
    position: absolute;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 190px;
}

.loader img:hover {
    cursor: pointer;
}

.fadeout {
    -webkit-clip-path: circle(0 at 50%);
    clip-path: circle(0 at 50%);
    pointer-events: none;

  /*animation: fadeout 2s forwards;*/
}
/*
@keyframes fadeout {
  to {
    -webkit-clip-path: circle(0 at 50%);
    clip-path: circle(0 at 50%);
    pointer-events: none;
  }
}
*/
</style>

